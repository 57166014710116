import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../config/theme/theme";

const productTypeStyles = makeStyles(() => ({
  accordion: {
    marginTop: "12px !important",
  },
  accordionSummary: {
    padding: "8px 24px",
  },
  accordionTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  accordionHeadingTitleStyle: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: 10,
  },
  accordionSubHeadingTitleStyle: {
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 10,
  },
  accordionDetails: {
    display: "block",
    padding: "12px",
    margin: "0px 0px 8px 0px",
    border: `1px solid ${COLORS.WHITE}`,
    background: COLORS.WHITE,
    borderRadius: "8px",
  },
  dFlex: { display: "flex" },
  foodSubType: {
    marginRight: "40px",
  },
  foodSubTypeCheckbox: { marginRight: "117px" },
  checkboxLabel: {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    paddingLeft: "8px",
  },
  nonFood: { marginTop: "16px" },
  productTypeLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  productTypeData: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  vertical: {
    borderLeft: "1px solid #B4B4B4",
    height: "25px",
    marginLeft: "80px",
    marginTop: "8px",
    marginRight: "80px",
  },
  error: {
    marginTop: "10px",
    fontSize: "12px",
    color: "red",
  },
  accordionHeaderSubTitleStyle: {
    margin: 0,
    padding: "4px 0px 0px 0px",
    fontSize: "12px",
    fontWeight: 400,
    color: COLORS.MINE_SHAFT_LIGHT,
  },
}));

export default productTypeStyles;
