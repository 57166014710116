import React from "react";
import moment from "moment";

import { Grid, Typography } from "@material-ui/core";

import SwitchButton from "components/common/SwitchButton/SwitchButton";
import SelectBox from "components/common/SelectBox/SelectBox";

import { MAXIMUM_MARK_BUSY_TIME_HOURS, MAXIMUM_MARK_BUSY_TIME_MINUTES, POS_ZONE_STATE_STATUS } from "../utils/constants";
import { isEmptyOrNull, isUndefined } from "../../../utils/DataUtils";
import { convertUTCDate } from "../../../utils/helperFunctions";

interface RenderExpressDefineBusyPeriodInterface {
  classes: any;
  state: string;
  stateEdit: boolean;
  busyDurationHour: number | string;
  busyDurationMinutes: number | string;
  busyEndTime?: any;
  timezone?: any;
  handleZoneStateSwitch?: any;
  handleChangeConfigurationStateSwitch: any;
  onBusyTimeFieldsChange: (name: string, event: any) => void;
}

const RenderExpressDefineBusyPeriod = (props: RenderExpressDefineBusyPeriodInterface) => {
  const { classes, state, stateEdit, busyDurationHour, busyDurationMinutes, busyEndTime, timezone, handleZoneStateSwitch, handleChangeConfigurationStateSwitch, onBusyTimeFieldsChange } = props;

  const convertDate = () => {
    if (busyEndTime) {
      if (timezone) {
        return moment(convertUTCDate(busyEndTime, timezone)).format("MMMM Do YYYY, h:mm a");
      }
      return moment(convertUTCDate(busyEndTime)).format("MMMM Do YYYY, h:mm a");
    }
    return "";
  };

  return (
    <Grid container spacing={1} className={classes.detailsSection}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography className={classes.headingTitleStyle}>{`Accepting Orders Configuration`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3} className={classes.marginAutoLeftright}>
            <SwitchButton
              leftLabel="Off"
              rightLabel="On"
              size="medium"
              checked={Boolean(state === POS_ZONE_STATE_STATUS.AVAILABLE)}
              handleChange={stateEdit ? undefined : handleZoneStateSwitch}
              onClick={(e: any) => e.stopPropagation()}
              disabled={false}
              className={stateEdit ? classes.disabledSwitch : ""}
            />
          </Grid>
          <Grid item xs={9}>
            {state === POS_ZONE_STATE_STATUS.BUSY ? (
              isEmptyOrNull(busyEndTime) ? (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <SelectBox
                      fieldLabel=""
                      name="busyDurationHour"
                      label="Hour"
                      value={busyDurationHour}
                      handleChange={(e: any) => onBusyTimeFieldsChange("busyDurationHour", e)}
                      menuItems={MAXIMUM_MARK_BUSY_TIME_HOURS}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectBox
                      fieldLabel=""
                      name="busyDurationMinutes"
                      label="Minute"
                      value={busyDurationMinutes}
                      handleChange={(e: any) => onBusyTimeFieldsChange("busyDurationMinutes", e)}
                      menuItems={MAXIMUM_MARK_BUSY_TIME_MINUTES}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Typography className={classes.textStyle}>
                  {`Zone Active Time `}
                  <Typography className={classes.textStyleBold}>{` : ${convertDate()}`}</Typography>
                </Typography>
              )
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderExpressDefineBusyPeriod);
