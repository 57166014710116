import { generateDropdownItems } from "../../../utils/helperFunctions";
import { DAYS_DROPDOWN_OPTIONS, FOOD_SUB_TYPES } from "../../../config/constants";

export const foodSplitRegex = ", ";
export const PRE_SELECTED_FOOD_SUB_TYPES = FOOD_SUB_TYPES.reduce((acc, subType) => [...acc, subType.value], []).join(foodSplitRegex);
export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc, day) => [...acc, day.id], []);

export const DEFAULT_TEMPLATE_SLOT = {
  startTime: null,
  endTime: null,
  slotCapacity: "",
  bufferTimeHours: "",
  bufferTimeMinutes: "",
  orderCutOff: "",
  status: true,
  isQuotaEnabled: false,
};

export const DEFAULT_SLOTS_GENERATOR_FORMS_VALUES = {
  days: [],
  slotDurationHours: "",
  slotDurationMinutes: 0,
  bufferTimeHours: "",
  bufferTimeMinutes: 0,
  serviceStartTime: null,
  serviceEndTime: null,
  defaultSlotCapacity: "",
  generatedSlots: [],
  quotas: [],
};

export const DEFAULT_TAB_CONFIGURATIONS = {
  zoneName: "",
  foodTypes: "",
  foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
  slotOptimizationEnabled: false,
  capacityThreshold: "",
  timeThreshold: "",
  slotsGeneratorForms: [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
  selectedAreas: [],
  status: "",
};

export const DEFAULT_ON_DEMAND_SLOT = { slotCapacity: "", startTime: null, endTime: null };

export const DEFAULT_ON_DEMAND_SLOT_GENERATOR_FORM = { days: [], generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] };

export const DEFAULT_TAB_BUFFER_CAPACITY_CONFIGURATIONS = { bufferCapacityConfig: { active: false, id: "", capacityUtilized: "", reducedBufferTime: "", maxCapacity: "" } };

// Enabling users to define time limit for busy periods
export const MAXIMUM_MARK_BUSY_TIME_HOURS = generateDropdownItems(0, 4, "hours");
export const MAXIMUM_MARK_BUSY_TIME_MINUTES = generateDropdownItems(0, 59, "minutes");
export const POS_ZONE_STATE_STATUS = { BUSY: "BUSY", AVAILABLE: "AVAILABLE" };
