export const posDetailsInRequiredFormat = (posDetails: any, serviceType: any, posAddress: any, currentCountryName: any) => {
  const body = {
    posNo: posDetails.posNumber,
    storeName: posDetails.storeName,
    posType: posDetails.posType,
    serviceType: serviceType.split(", "),
    storeTimings: [],
    posContacts: [
      {
        id: posAddress.contactId || null,
        name: posAddress.contactName || "",
        email: posAddress.email || "",
        phoneNumber: posAddress.contactNo || null,
      },
    ],
    posAddress: {
      id: posAddress.id,
      addressLine1: posAddress.addressLine1,
      addressLine2: posAddress.addressLine2,
      country: currentCountryName,
      region: posAddress.region,
      area: posAddress.area,
      street: posAddress.street,
      poBox: posAddress.poBox,
      latitude: posAddress.latitude,
      longitude: posAddress.longitude,
    },
  };
  return body;
};
