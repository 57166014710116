import React from "react";
import { Link } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Tooltip, Typography } from "@material-ui/core";

import { SERVICE_TYPES, STORE_STATE, ZONE_CHANGE_STATUS } from "../../../../config/constants";
import { PosStatusToggle } from "../../../POSList/posUtils/PosAccessUtils";
import { is_zone_service_toggle_visible } from "../../CompleteSetupUtils";
import { getKeyNameFromZoneTab } from "../../../../utils/ServiceTypeUtils";
import { POS_ZONE_STATE_STATUS } from "../../../ServiceAndSlots/utils/constants";

import Loader from "../../../common/Loader/Loader";

import ExpandMoreIcon from "../../../assets/svg/ExpandAccordionIcon.svg";
import RenderDefaultSupplierAllInformation from "../../../DefaultServiceType/RenderDefaultSupplierAllInformation";
import RenderCollectionPoints from "../../../ClickAndCollectServiceType/RenderCollectionPoints";
import RenderServiceAllInformation from "./RenderServiceAllInformation";

interface DetailedSlotInformationOnCompleteSetupInterface {
  classes: any;
  userInfo: any;
  toggling: any;
  tab: any;
  tabIndex: any;
  serviceTypeName: any;
  handleActivateDeactivateZoneConfigClick: any;
  handleCollectionPointToggleSwitch: any;
  posNo: any;
  handleExpressStoreStateChange?: any;
}

export const DetailedSlotInformationOnCompleteSetup = (props: DetailedSlotInformationOnCompleteSetupInterface) => {
  const { classes, userInfo, toggling, tab, tabIndex, serviceTypeName, handleActivateDeactivateZoneConfigClick, handleCollectionPointToggleSwitch, posNo, handleExpressStoreStateChange } = props;

  const showCapacityFlag = () => {
    return serviceTypeName !== SERVICE_TYPES.DEFAULT;
  };

  return (
    <Accordion key={tab.zoneConfigId || Math.random()} className={classes.zoneAccordionStyle}>
      <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.zoneNameTextStyle}>{`${getKeyNameFromZoneTab(tab, "zoneName")} ${
              showCapacityFlag() ? `(Capacity - ${tab.capacityAmountAtZoneLevel || "0"})` : ""
            }`}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              {serviceTypeName === SERVICE_TYPES.EXPRESS && getKeyNameFromZoneTab(tab, "status") === ZONE_CHANGE_STATUS.ACTIVATED ? (
                getKeyNameFromZoneTab(tab, "state") === POS_ZONE_STATE_STATUS.AVAILABLE ? (
                  <Typography className={classes.availableZoneSpanStyle}>{`Accepting Orders`}</Typography>
                ) : (
                  <Typography className={classes.busyZoneSpanStyle}>{`Not Accepting Orders`}</Typography>
                )
              ) : (
                <></>
              )}
              {is_zone_service_toggle_visible(userInfo) ? (
                toggling ? (
                  <Grid item xs={5} className={classes.posRelative}>
                    <Loader />
                  </Grid>
                ) : (
                  <PosStatusToggle
                    userInfo={userInfo}
                    classes={classes}
                    checked={getKeyNameFromZoneTab(tab, "status") === ZONE_CHANGE_STATUS.ACTIVATED ? true : false}
                    handleServiceActiveInactiveSwitch={(e: any) => handleActivateDeactivateZoneConfigClick(e, tabIndex, getKeyNameFromZoneTab(tab, "id"))}
                    disabled={toggling}
                  />
                )
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.serviceAccDetails} onClick={(e) => e.stopPropagation()}>
        {serviceTypeName === SERVICE_TYPES.DEFAULT ? (
          <RenderDefaultSupplierAllInformation classes={classes} supplierTypes={tab?.supplierTypes} />
        ) : serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT ? (
          <RenderCollectionPoints
            classes={classes}
            tab={tab}
            collectionPoints={tab?.collectionPoints}
            userInfo={userInfo}
            handleCollectionPointToggleSwitch={handleCollectionPointToggleSwitch}
            serviceTypeName={serviceTypeName}
          />
        ) : (
          <RenderServiceAllInformation classes={classes} tab={tab} serviceTypeName={serviceTypeName} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(DetailedSlotInformationOnCompleteSetup);
