import produce from "immer";
import moment from "moment";

import { ALERT_TYPES, HH_MM_SS } from "../../../../../../config/constants";
import { convertMinutesToHours } from "../../../../../../utils/helperFunctions";
import { getFoodTypesFromFoodSubTypes } from "../../../../utils/SlotServicesUtils";
import { foodSplitRegex } from "../../../../utils/constants";
import { isEmptyOrNull } from "../../../../../../utils/DataUtils";

import CustomAlert from "../../../../../common/CustomAlert/CustomAlert";

export const design_express_configuration_handler_utils = (slots: any, zoneId: any) => {
  let new_config_tabs = {} as any;
  if (slots !== undefined && Array.isArray(slots) && slots.length > 0) {
    slots.forEach((slot: any) => {
      if (slot !== undefined && slot.zoneConfigId !== undefined && slot.zoneConfigId !== "" && slot.zoneConfigId.toString() === zoneId.toString()) {
        new_config_tabs = {
          // Zone
          zoneId: slot?.zoneConfig?.id || "",
          zoneName: slot?.zoneConfig?.zoneName || "",
          zoneStatus: slot?.zoneConfig?.status || "",
          // Food Type
          foodTypes: getFoodTypesFromFoodSubTypes(slot?.foodTypeMapping?.foodTypes),
          foodSubType: slot.foodTypeMapping.foodTypes?.filter((fst: any) => fst !== "NON_FOOD").join(foodSplitRegex),
          showFirstSlotAtOption: !!slot.zoneConfig.showFirstSlotAt,
          showFirstSlotAt: slot.zoneConfig.showFirstSlotAt ? moment(slot.zoneConfig.showFirstSlotAt, HH_MM_SS) : null,
          showServiceCutOffTimeOption: !!slot.zoneConfig.serviceCutOffTime,
          serviceCutOffTime: slot.zoneConfig.serviceCutOffTime ? moment(slot.zoneConfig.serviceCutOffTime, HH_MM_SS) : null,
          selectedAreas: slot.selectedAreas || [],
          // Slot
          slotsGeneratorForms: Object.keys(slot.dayWiseSlots).map((dayWiseSlot) => ({
            isEdit: true,
            days: [dayWiseSlot],
            generatedSlots:
              slot.dayWiseSlots[dayWiseSlot] !== undefined && Array.isArray(slot.dayWiseSlots[dayWiseSlot])
                ? slot.dayWiseSlots[dayWiseSlot].map((slot: any) => ({
                    ...slot,
                    startTime: moment(slot.startTime, HH_MM_SS),
                    endTime: moment(slot.endTime, HH_MM_SS),
                    status: slot.status,
                    time: `${moment(slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(slot.endTime, HH_MM_SS).format("HH:mm")}`,
                    bufferTimeHours: convertMinutesToHours(slot.bufferTime).hours,
                    bufferTimeMinutes: convertMinutesToHours(slot.bufferTime).minutes,
                    orderCutOff: moment(slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(slot.bufferTime).minutes, "m").format("HH:mm"),
                    isEditable: false,
                    canBeUpdated: false,
                    slotAction: "UPDATE", // ADD, UPDATE, DELETE
                  }))
                : {},
          })),
          status: slot?.zoneConfig?.status || "",
        };
      }
    });
  }
  return new_config_tabs;
};

export const update_make_slot_editable = (configTab: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated = !draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated;
  });
  return new_config_tab;
};

export const update_handle_individual_slot_field_change = (configTab: any, generatorFormIndex: any, slot_index: any, name: any, value: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index][name] = value;
    if (draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction !== "ADD") {
      draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction = "UPDATE";
    }
  });
  return new_config_tab;
};

export const add_new_template_slot_util = (configTab: any, generatorFormIndex: any, zoneId: any, days: any, posDetails: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    let newSlot = {
      startTime: null,
      endTime: null,
      slotCapacity: "",
      //
      posId: posDetails.id,
      day: days,
      zoneId: zoneId,
      //
      isNewSlot: true,
      canBeUpdated: true,
      slotAction: "ADD",
    };
    draft.slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newSlot);
  });
  return new_config_tab;
};

export const delete_template_slot_util = (configTab: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (
      draft.slotsGeneratorForms !== undefined &&
      Array.isArray(draft.slotsGeneratorForms) &&
      draft.slotsGeneratorForms.length > 0 &&
      draft.slotsGeneratorForms[generatorFormIndex] !== undefined &&
      draft.slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined &&
      Array.isArray(draft.slotsGeneratorForms[generatorFormIndex].generatedSlots) &&
      draft.slotsGeneratorForms[generatorFormIndex].generatedSlots.length > 0
    ) {
      if (
        draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index] !== undefined &&
        draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== undefined &&
        draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== ""
      ) {
        const { startTime, endTime, slotCapacity } = draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index];
        let responseValue: any = {};
        if (isEmptyOrNull(startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide slot start time before delete";
        } else if (!moment(startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide valid slot start time before delete";
        }

        if (isEmptyOrNull(endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide slot end time before delete";
        } else if (!moment(endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide valid slot end time before delete";
        }

        if (isEmptyOrNull(slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-slotCapacity`] = "Please provide valid slot capacity before delete";
        }

        if (responseValue && Object.keys(responseValue).length !== 0) {
          CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
        } else {
          draft.slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction = "DELETE";
        }
      } else {
        let allSlots = draft.slotsGeneratorForms[generatorFormIndex].generatedSlots;
        let filteredSlots = allSlots.filter((item: any) => !!item.id);
        draft.slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
      }
    }
  });
  return new_config_tab;
};

export const validate_generator_form_before_bulk_update = (configTab: any) => {
  let responseValue: any = {};
  if (
    configTab !== undefined &&
    configTab.zoneId !== undefined &&
    configTab.zoneId !== "" &&
    configTab.slotsGeneratorForms !== undefined &&
    Array.isArray(configTab.slotsGeneratorForms) &&
    configTab.slotsGeneratorForms.length > 0
  ) {
    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
        const start_error_message = `Please provide slot start time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const end_error_message = `Please provide slot end time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const capacity_error_message = `Please provide valid slot capacity ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;

        if (isEmptyOrNull(slot.startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        } else if (!moment(slot.startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        }
        // else {
        //   slot_form.generatedSlots.forEach((prev_slot: any, prev_slot_index: any) => {
        //     if (slot.slotAction !== "DELETE") {
        //       if (prev_slot_index !== undefined && prev_slot_index !== slot_index && prev_slot !== undefined && !isEmptyOrNull(prev_slot.startTime) && !isEmptyOrNull(prev_slot.endTime)) {
        //         if (moment(slot.startTime).isBetween(moment(prev_slot.startTime), moment(prev_slot.endTime))) {
        //           responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-time`] = `Slot overlapping found ${
        //             slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
        //           }`;
        //         }
        //       }
        //     }
        //   });
        // }

        if (isEmptyOrNull(slot.endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        } else if (!moment(slot.endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        }

        if (isEmptyOrNull(slot.slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = capacity_error_message;
        }
      });
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return false;
  }

  return true;
};

export const bulk_update_slot_changes_util = (configTab: any, currentDay: any, selctedDays: any, slotForm: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft.slotsGeneratorForms.forEach((slot: any, slotIndex: any) => {
      if (
        slot !== undefined &&
        slot.days !== undefined &&
        Array.isArray(slot.days) &&
        slot.days.length > 0 &&
        slot.days[0] !== undefined &&
        slot.days[0] !== "" &&
        currentDay !== undefined &&
        currentDay !== "" &&
        slot.days[0] !== currentDay &&
        selctedDays.indexOf(slot.days[0]) > -1
      ) {
        let slot_form_copy = JSON.parse(JSON.stringify(slotForm));
        draft.slotsGeneratorForms[slotIndex].generatedSlots = [];

        if (slot_form_copy !== undefined) {
          if (
            slot_form_copy.days !== undefined &&
            Array.isArray(slot_form_copy.days) &&
            slot_form_copy.days.length > 0 &&
            slot_form_copy.days[0] !== undefined &&
            slot_form_copy.days[0] !== undefined
          ) {
            slot_form_copy.days[0] = slot.days[0];
          }

          if (slot_form_copy.generatedSlots !== undefined && Array.isArray(slot_form_copy.generatedSlots) && slot_form_copy.generatedSlots.length > 0) {
            slot_form_copy.generatedSlots.forEach((data: any) => {
              data.day = slot.days[0];
              data.id = 0;
              data.slotAction = "ADD";
            });

            draft.slotsGeneratorForms[slotIndex].generatedSlots = [...slot_form_copy.generatedSlots];
          }
        }
      }
    });
  });
  return new_config_tab;
};

export const _validate_send_for_approval_form = (originalConfigTab: any, configTab: any, dateObject: any, countryId: any, stateObj: any) => {
  let pay_load_object = {} as any;
  let responseValue: any = {};
  let is_different_value_found: boolean = false;

  if (
    configTab !== undefined &&
    configTab.zoneId !== undefined &&
    configTab.zoneId !== "" &&
    configTab.slotsGeneratorForms !== undefined &&
    Array.isArray(configTab.slotsGeneratorForms) &&
    configTab.slotsGeneratorForms.length > 0
  ) {
    pay_load_object = {
      countryId: countryId,
      activationDate: dateObject.activationDate,
      serviceTypeId: "",
      cncId: null,
      zoneConfig: {
        id: configTab?.zoneId || "",
        zoneName: configTab?.zoneName || "",
        serviceCutOffTime: configTab?.zoneServiceCutOffTime || "",
        showFirstSlotAt: configTab?.zoneShowFirstSlotAt || "",
        serviceTypeMappingId: configTab?.zoneServiceTypeMappingId || "",
        capacityThreshold: configTab?.zoneCapacityThreshold || "",
        timeThreshold: configTab?.zoneTimeThreshold || "",
        status: configTab?.zoneStatus || "",
      },
      templateSlots: [],
      updatedDays: [],
      defaultTemplateSlots: [],
      requesterComments: "",
    };

    if (stateObj !== undefined && stateObj.approverComment !== undefined && stateObj.approverComment !== "") {
      pay_load_object.requesterComments = stateObj.approverComment;
    }

    if (
      stateObj !== undefined &&
      stateObj.configurationBulkUpdatedObj !== undefined &&
      stateObj.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(stateObj.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      stateObj.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      pay_load_object.updatedDays = [...stateObj.configurationBulkUpdatedObj.bulkUpdatedDays];
    }

    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      let day_name = "";
      if (slot_form.days !== undefined && Array.isArray(slot_form.days) && slot_form.days.length > 0 && slot_form.days[0] !== undefined && slot_form.days[0] !== "") {
        day_name = `for ${slot_form.days[0]}`;
      }
      if (slot_form !== undefined && slot_form.generatedSlots !== undefined && Array.isArray(slot_form.generatedSlots) && slot_form.generatedSlots.length > 0) {
        let is_generated_slot_deleted: number = 0;
        slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
          if (slot !== undefined) {
            let template_slot_object = {
              id: slot?.id || 0,
              posId: slot.posId,
              day: slot.day,
              zoneId: slot.zoneId,
              status: slot.status,
              createdAt: slot.createdAt,
              slotAction: slot?.slotAction || "UPDATE", // ADD, UPDATE, DELETE
              startTime: null,
              endTime: null,
              slotCapacity: null,
              serviceTypeId: slot?.serviceTypeId || 3,
              bufferTime: 0,
            } as any;

            if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "") {
              if (slot.slotAction === "DELETE") {
                is_generated_slot_deleted++;
                is_different_value_found = true;
              }

              if (isEmptyOrNull(slot.startTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.startTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide valid slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.startTime = moment(slot.startTime).format(HH_MM_SS);
                slot_form.generatedSlots.forEach((newSlot: any, newSlotIndex: any) => {
                  if (
                    newSlotIndex !== undefined &&
                    newSlotIndex !== slot_index &&
                    newSlot.slotAction !== undefined &&
                    newSlot.slotAction !== "" &&
                    newSlot.slotAction !== "DELETE" &&
                    newSlot !== undefined &&
                    !isEmptyOrNull(newSlot.startTime) &&
                    !isEmptyOrNull(newSlot.endTime)
                  ) {
                    let startTimeFlag = moment(newSlot.startTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                    if (!startTimeFlag) {
                      startTimeFlag = moment(newSlot.startTime).isSame(moment(slot.startTime));
                    }
                    //
                    let endTimeFlag = moment(newSlot.endTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                    if (!endTimeFlag) {
                      endTimeFlag = moment(newSlot.endTime).isSame(moment(slot.endTime));
                    }
                    if (startTimeFlag && endTimeFlag) {
                      let error_message = "One or more slots is a subset of another slot. Please change slot timings.";
                      responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-time`] = error_message;
                    }
                  }
                });
              }

              if (isEmptyOrNull(slot.endTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.endTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide valid slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.endTime = moment(slot.endTime).format(HH_MM_SS);
              }

              if (moment(slot.endTime).isBefore(moment(slot.startTime))) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot start/end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              }

              if (isEmptyOrNull(slot.slotCapacity)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot capacity ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.slotCapacity = parseInt(slot.slotCapacity);
              }

              if (
                originalConfigTab !== undefined &&
                originalConfigTab.slotsGeneratorForms !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms) &&
                originalConfigTab.slotsGeneratorForms.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index] !== undefined &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots) &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index] !== undefined
              ) {
                //
                // to check if change is made or the values are same and it is requested for approval
                //
                const { startTime, endTime, slotCapacity } = originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index];

                if (originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length !== slot_form.generatedSlots.length) {
                  is_different_value_found = true;
                }

                if (slotCapacity !== undefined) {
                  if (parseInt(slotCapacity) !== parseInt(slot.slotCapacity)) {
                    is_different_value_found = true;
                  }
                }

                if (startTime !== undefined) {
                  if (!moment(startTime).isSame(moment(slot.startTime))) {
                    is_different_value_found = true;
                  }
                }

                if (endTime !== undefined) {
                  if (!moment(endTime).isSame(moment(slot.endTime))) {
                    is_different_value_found = true;
                  }
                }
              }
            }

            if (pay_load_object.serviceTypeId === "") {
              pay_load_object.serviceTypeId = slot.serviceTypeId;
            }
            pay_load_object.templateSlots.push(template_slot_object);
          }
        });

        if (slot_form.generatedSlots.length === is_generated_slot_deleted) {
          responseValue[`slot_index-${slot_form_index}-slot_form_index-is_generated_slot_deleted`] = `No Slot found for ${day_name}.`;
        }
      } else {
        responseValue[`slot_index-${slot_form_index}-slot_form_index-is_generated_slot_deleted`] = `No Slot present for ${day_name}.`;
      }
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return {};
  }

  if (is_different_value_found === false) {
    CustomAlert(ALERT_TYPES.ERROR, "No change found for approval");
    return {};
  }

  return pay_load_object;
};
